@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #fed7aa;
  font-family: inherit;
}

@media print {
  body {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    background-color: #fed7aa;
    min-height: 100vh; /* Set the minimum height to 100vh (100% of the viewport height) */
    max-height: 100vh; /* Set the maximum height to 100vh */
    height: 100vh; /* Set the height to 100vh */
    overflow: hidden; /* Hide any content that exceeds the viewport height */
  }

  @page :first {
    size: A4 landscape;
    margin: 1cm;
  }

  .section-to-print {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    padding: 0;
    page-break-before: always;
    page-break-inside: avoid;
    page-break-after: auto;
    overflow: auto;
    height: 100%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.item-fade-in {
  animation: fadeIn 0.5s ease-in-out;
}
