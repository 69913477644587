.search-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.search-bar {
  flex: 1 0 auto;
  margin-right: 1rem;
}

.search-bar input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.filter {
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.filter label {
  margin-right: 0.5rem;
}

.filter select {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .search-container {
    flex-direction: column;
    align-items: stretch;
  }

  .search-bar {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .filters {
    justify-content: flex-start;
  }

  .filter {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
